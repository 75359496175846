@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-add-vendor-page {
  width: 640px;
  margin: auto;

  .add-vendor-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .add-vendor-title {
      display: inline-block;
      font-size: 24px;
    }

    .add-vendor-back-btn {
      display: inline-block;
      margin-right: 16px;
    }
  }

  .add-vendor-content-panel {
    width: 100%;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px 40px;

    .add-vendor-content-title {
      font-weight: 700;
      font-size: 22px;
    }

    .add-vendor-inputs-wrapper {
      width: 360px;
      margin: 40px auto 32px;

      .add-vendor-input {
        margin-bottom: 36px;
        margin-top: 40px;
      }

      .add-vendor-submit-btn {
        margin-top: 16px;
        width: 100%;
        justify-content: center;

        .spinner-display {
          top: 1px;
        }
      }
    }
  }
}

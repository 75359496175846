@import '@doseme/cohesive-ui/dist/style/colors.scss';

.resource-back-btn {
  display: inline-block;
  margin-top: 40px;
  margin-right: 16px;
}

.resource-title {
  color: $bright-blue;
  display: inline-block;
  margin-top: 48px;
  font-size: 24px;
  font-weight: bold;

  &.help-videos {
    color: $validgreen;
  }

  &.user-guides {
    color: $bright-blue;
  }

  &.clinical-competencies {
    color: $magenta;
  }

  &.drug-information {
    color: $ocean-blue;
  }

  &.clinical-resources {
    color: $doseme-warning;
  }

  &.compliance {
    color: $plot-individualised;
  }
}

.resource-panel {
  position: relative;
  border-radius: 24px;
  padding: 32px 40px;
  background-color: $snow;
  box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
  margin-top: 32px;

  .resource-panel-title {
    font-size: 18px;
    color: $charcoal-grey;
    margin-bottom: 40px;
  }

  .resource-panel-subtitle {
    font-size: 18px;
  }
}

.download-link-div {
  padding: 16px 24px;
  background-color: $pale-blue;
  border: 2px solid transparent;
  border-radius: 16px;
  margin-top: 24px;
  display: flex;
  position: relative;

  &.compliance {
    margin-top: 0px;
  }

  .link-div-wrapper {
    width: 85%;

    .doseme-link-div-title {
      color: $gunmetal;
      font-size: 18px;
      font-weight: 700;
    }

    .doseme-link-div-text {
      font-size: 16px;
      width: 100%;
      position: relative;
    }
  }

  .download-link-div-import-icon {
    position: absolute;
    right: 0px;
    top: calc(50% - 10px);
    margin-right: 24px;
    align-self: center;
  }
}

.download-link-div:hover {
  border: 2px solid $dark-shadow;
  cursor: pointer;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.twofa-setup-page-panel {
  width: 640px;
  border-radius: 24px;
  background-color: $white;
  box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
  margin-top: 64px;
  padding: 32px 40px;

  .twofa-setup-page-text {
    font-size: 14px;
  }

  .twofa-setup-page-title {
    color: $doseme-blue;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .twofa-setup-page-subtitle {
    font-weight: 700;
    color: $charcoal-grey;
    margin-bottom: 8px;
    font-size: 14px;

    &.larger-font {
      font-size: 16px;
    }
  }

  .twofa-setup-page-description {
    margin-top: 8px;
    margin-bottom: 24px;
    color: $charcoal-grey;
  }

  .totp-images-div {
    border-radius: 16px;
    background: #f2f2f2;
    padding: 24px;
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;

    &.code-input {
      margin-top: 10px;
    }

    .qr-code-div {
      padding: 12px;
      border-radius: 16px;
      background-color: $white;
      margin-left: auto;
      margin-right: auto;

      .twofasetup-page-set-up-key {
        font-family: 'Menlo';
        font-size: 24px;
        margin-bottom: 16px;
        text-align: center;
        max-width: 480px;
      }
    }

    .totp-images {
      margin-left: 32px;

      &.left {
        margin-left: 68px;
      }

      .totp-images-text {
        color: $charcoal-grey;
        font-size: 14px;
        text-align: center;
        margin-top: 12px;
        width: 104px;
      }
    }
  }

  .twofasetup-digit-input-div {
    display: flex;
    justify-content: center;
  }

  .twofasetup-error {
    margin-top: 16px;
    width: 360px;
    margin-left: auto;
    margin-right: auto;
  }

  .twofa-setup-page-input-title {
    text-align: center;
  }

  .twofasetup-page-code-input-subtitle {
    width: fit-content;
    font-size: 14px;
    padding: 0px 4px 2px 4px;
    border-radius: 4px;
    background-color: $input-grey;
  }

  .twofasetup-page-submit-btn {
    margin-top: 32px;

    .btn-primary-alt {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .twofa-submit-btn-label {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -35%);
    }
  }

  .twofasetup-page-hyperlink-text {
    font-size: 14px;
    color: $navy;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 24px;

    &.qr-code {
      font-size: 12px;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.clinician-hospitals-container {
  .message-box {
    padding: 0px !important;
    background-color: unset !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
  }

  .clinician-hospitals {
    display: flex;
    flex-direction: column;
    width: 100%;

    .clinician-hospitals-search-bar-container {
      width: 100%;
      height: 64px;
      background-color: $menu-grey;
      border-radius: 12px;
      padding: 12px 16px;
      display: flex;
      position: relative;
      margin-bottom: 8px;
    
      .clinician-hospitals-search-bar {
        width: 100%;

        .co-smart-list-search {
          width: 100% !important;
        }
      }
    }
  }
}

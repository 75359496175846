@import '@doseme/cohesive-ui/dist/style/colors.scss';

.model-fit-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  .model-fit-paragraph {
    margin: 8px 0px;
  }

  .model-selection-important-header {
    color: $bright-blue;
    font-weight: 700;
  }

  .model-fit-subheading {
    height: 16px;
    display: flex;
    flex-direction: row;
    font-weight: 700;
    color: $charcoal-grey;
    font-size: 14px;
  }

  .model-fit-text-div {
    padding: 12px 16px 0px 16px;
    border-radius: 6px;
  }

  .model-fit-text-div-dark {
    background-color: $menu-grey;
  }

  .model-fit-modal-icons {
    padding-right: 8px;
  }

  .model-fit-horizontal-line {
    border: none;
    height: 1px;
    background-color: $loading-grey;
    margin: 16px 0px;
  }

  .model-fit-notes {
    font-style: italic;
    font-weight: 700;
  }

  .model-fit-list {
    padding-inline-start: 16px;
    margin: 8px 0px;
  }

  .model-selection-paragraph-infobubble {
    color: black;
    font-size: 14px;
    margin-left: 25px;
  }

}

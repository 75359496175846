@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-clinician-invite-page {
  width: 640px;
  margin: auto;

  .clinician-invite-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .clinician-invite-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .clinician-invite-title {
      display: inline-block;
      font-size: 24px;
    }
  }

  .clinician-invite-content-panel {
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;
    position: relative;

    .clinician-invite-content-title {
      font-weight: 700;
      font-size: 22px;
      padding-bottom: 40px;
    }

    .clinician-invite-spinner {
      margin-left: 150px;
    }

    .clinician-invite-form-content {
      width: 360px;
      margin: auto;

      .clinician-invite-hospitals {
        border-radius: 8px;
        background-color: $neutrals-100;
        padding: 8px;

        .clinician-invite-hospitals-selected-title {
          color: $charcoal-grey;
          font-weight: 700;
          font-size: 14px;
          margin-bottom: 8px;
        }

        .clinician-invite-hospitals-none-all-selected {
          color: $charcoal-grey;
          font-weight: 400;
          font-style: italic;
          font-size: 14px;
        }

        .clinician-invite-hospitals-selected-items-scroll {
          overflow: auto;
          max-height: 200px;
        }

        .clinician-invite-hospitals-selected-items {
          gap: 4px;
          display: flex;
          flex-direction: column;

          .clinician-invite-hospitals-selected {
            font-size: 14px;
            height: 32px;
            background-color: $neutrals-50;
            display: flex;
            align-items: center;
            border-radius: 8px;
            padding-left: 12px;

            .clinician-invite-hospitals-selected-icon-button {
              margin-left: auto;
              color: $loading-grey
            }
          }
        }
      }

      .clinician-invite-button-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 14px;

        .clinician-invite-button {
          margin-right: 12px;
        }
      }
    }
  }
}

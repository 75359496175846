@import '@doseme/cohesive-ui/dist/style/colors.scss';

.forgot-password-panel {
  .forgot-password-subtitle-email {
    width: fit-content;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: $input-grey;
    margin-bottom: 16px;
  }

  .forgot-password-body {
    margin-top: 8px;
    font-size: 14px;
  }

  .forgot-password-email-input-label {
    margin-top: 24px;
    font-weight: 700;
    font-size: 14px;
  }

  .forgot-password-info-bubble {
    margin-top: 24px;
  }

  .forgot-password-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;

    .btn-primary-alt {
      margin-top: 0px;
    }

    .login-page-password-back-arrow {
      margin-bottom: 0px;
      align-items: center;
    }
  }
}

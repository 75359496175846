@import '@doseme/cohesive-ui/dist/style/colors.scss';

.twofa-page-panel {
  width: 440px;
  border-radius: 24px;
  background-color: $white;
  box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
  margin-top: 64px;
  padding: 40px 48px;
  position: relative;

  .twofa-page-title {
    color: $doseme-blue;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .twofa-page-email-subtitle {
    width: fit-content;
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: $input-grey;
  }

  .twofa-page-loading-spinner {
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 8px);

    &.success {
      transform: translate(-50%, 20%);
    }
  }

  .loading-opacity-outer {
    opacity: 0.4;
  }

  .twofa-page-input-title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-top: 32px;
  }

  .twofa-page-inputs-outer {
    display: flex;
    margin-top: 16px;
    margin-left: 12px;

    .twofa-page-input {
      width: 44px;
      height: 48px;
      margin-right: 8px;
      border-radius: 8px;
      text-align: center;
      font-size: 32px;
      border: 1px solid rgb(218, 218, 218);

      &.third {
        margin-right: 24px;
      }
    }
    input:focus {
      outline: none !important;
      border-color: rgba(131, 143, 161, 1);
    }
  }

  .twofa-page-hyperlink-text {
    font-size: 14px;
    color: $navy;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 22px;

    &.more-info {
      margin-top: 16px;
    }
  }

  .twofa-page-error-message {
    margin-top: 32px;

    .co-information-bubble-error {
      border-color: rgba($error-red, 0.1);
    }
  }
}

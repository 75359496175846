@import '@doseme/cohesive-ui/dist/style/colors.scss';

.recharts-legend-wrapper {
  z-index: 0 !important;
}

.dosing-profile-plot-container {
  position: relative;
}

.dosing-profile-plot {
  position: absolute;
  z-index: -1;
}

.dosing-profile-header {
  margin-bottom: 16px;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.header-loading-spinner {
  display: inline-block;
  margin-left: 20px;
}

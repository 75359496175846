@import '@doseme/cohesive-ui/dist/style/colors.scss';

.hospital-patient-list-content {
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;

  &.wide {
    max-width: 80%;
    min-width: 1220px;
  }
  
  &.medium {
    width: 100%;
  }
  
  &.narrow {
    width: 800px;
  }
}

.patient-list-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 56px;
  width: 100%;
  padding: 0 12px;

  .patient-list-title {
    font-size: 24px;
    font-weight: normal;
    padding-right: 24px;;
    max-width: 544px;

    .patient-list-hospital-name {
      font-weight: 700;
    }
  }

  .patient-list-archived-filter {
    margin-left: auto;
    width: 200px;
  }
}

.patient-list-search {
  padding-left: 12px;
  margin-bottom: 16px;
  width: 50%;
}

.patient-list-filter {
  margin-bottom: 16px;
  flex-wrap: wrap;
  height: fit-content;
  padding-left: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.patient-list-filter-bold {
  font-weight: 700;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-vendorsite-list-page {
  max-width: 1080px;
  width: auto;
  padding: 0px 24px 0px 24px;
  margin: auto;

  .vendorsite-list-dropdown-tooltip {
    position: relative;
    min-width: 285px;
    left: -265px;
    top: 10px;
  }

  .vendorsite-list-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .vendorsite-list-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .vendorsite-list-title {
      flex-direction: row;
      display: flex;
      font-size: 24px;

      .vendorsite-list-name {
        font-weight: bold;
      }
    }

    .create-new-vendorsite-btn {
      margin-left: 40px;
    }
  }

  .vendorsite-list-content-panel {
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;

    .vendorsite-list-search-bar-container {
      width: 100%;
      height: 64px;
      background-color: $menu-grey;
      border-radius: 12px;
      padding: 12px 16px;
      display: flex;
      position: relative;

      .vendorsite-list-search-bar {
        position: absolute;
        right: 0px;
      }
    }

    .vendorsite-list-list {
      margin-top: 8px;

      .vendorsite-list-action-button {
        padding: 5px;
      }
    }
  }
}

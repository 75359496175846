@import '@doseme/cohesive-ui/dist/style/colors.scss';

.median-hours-and-doses-to-target {
  width: 352px;
  margin-left: 24px;

  .co-basic-list {
    border: 1px solid $lightgrey;
    border-radius: 8px;
  }

  .co-basic-list-header {
    height: 64px;
    font-size: 13px;
    gap: 4px;
    padding: 0px 8px;
  }

  .co-basic-list-row-height {
    gap: 4px;
    padding: 0px 8px;
  }

  .drug-column {
    width: 88px;
    max-width: 88px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0px !important;
    font-size: 13px;
  }

  .data-column {
    width: 56px;
    padding: 0px !important;
    font-size: 13px;
  }

  .overall-row {
    border-top: 2px solid $lightgrey;

    .drug-column {
      font-weight: 700;
      color: $neutrals-700;
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.docetaxel-simulation-panel-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.docetaxel-simulation-title {
  font-size: 22px;
  font-weight: 700;
}

.docetaxel-simulation-title-disabled {
  color: $neutrals-400;
}

.docetaxel-simulation-warning-title {
  font-weight: 700;
  font-size: 16px;
  color: $neutrals-900;
  gap: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.docetaxel-simulation-warning-subtitle {
  font-size: 16px;
  color: $neutrals-600;
  margin-bottom: 8px;
}

.docetaxel-simulation-warning {
  gap: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 12px;
}

.simulated-gcsf-bar {
  height: 48px;
  font-size: 0.9375rem;
  border-radius: 16px;
  padding: 0 12px;
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $neutrals-100;

  .co-list-button {
    color: $black;
  }
}

.next-cycle-bar {
  height: 48px;
  font-size: 0.9375rem;
  border-radius: 16px;
  padding: 0 12px;
  margin-top: 16px;
  background-color: $pale-orange;
  display: flex;
  align-items: center;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.model-selection-outer {
  background-color: $menu-grey;
  height: 124px;
  border-radius: 24px;
  margin-top: 16px;
  padding: 24px 32px 32px 32px;

  .model-selection-title {
    color: $loading-grey;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .model-selection-text {
    font-size: 16px;
    font-weight: 700;
  }
}

.add-course {
  max-width: 512px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;

  .add-course-title {
    font-size: 24px;
    font-weight: normal;
    margin-top: 7px;
    margin-left: 16px;
  }

  .add-course-form-model-selection-information-outer {
    width: 570px;

    .info-modal-title {
      font-size: 14px;
      font-weight: 700;
    }

    .info-modal-text {
      font-size: 14px;
    }

    .info-modal-important-information {
      color: black;
      margin-left: 25px;
    }

    .add-course-info-modal-svg {
      width: 586px;
    }
  }
}

.model-selection-loading-spinner {
  margin-left: 50%;
  transform: translateX(-10%);
}

.add-course-info-box {
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
  margin-left: auto;
  background-color: $input-grey;
  color: $charcoal-grey;
  width: 132px;
  height: 24px;
  border-radius: 8px;
  padding: 5px 8px;
  display: flex;

  .add-course-info-icon {
    margin-left: 8px;
    transform: translateY(-1px);
    cursor: pointer;
  }

  .add-course-info-icon:hover {
    transform: scale(1.5) translateY(-1px);
  }
}

.add-course-suggested-model-outer {
  border-radius: 16px;
  border: 2px solid $input-grey;
  margin-top: 24px;
  padding: 16px 24px;

  .add-course-suggested-model-title {
    font-size: 16px;
    font-weight: 700;
  }

  .add-course-suggested-model-subtitle {
    font-size: 14px;
    color: $charcoal-grey;
    margin-top: 4px;
  }

  .no-applicable-models {
    height: 32px;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: $input-grey;
    font-style: italic;
    font-size: 14px;
    margin-top: 8px;
  }
}

.unapplicable-models-outer {
  padding: 14px 24px;
  border-radius: 16px;
  background-color: $menu-grey;
  margin-top: 16px;
  display: relative;
  cursor: pointer;

  &.closed {
    height: 48px;
  }

  .unapplicable-models-title {
    font-weight: 700;
    font-size: 14;
    margin-bottom: 4px;
    width: 100%;
  }

  .unapplicable-models-chevron-outer {
    margin-top: 2px;
    color: $loading-grey;
    display: flex;
    font-size: 14px;

    .unapplicable-models-chevron {
      display: flex;
      align-items: center;

      &.activated {
        transform: rotate(180deg) translateY(3px);
      }
    }
  }

  .unapplicable-models-item {
    padding: 8px 12px 1px 12px;
    border-radius: 8px;
    background-color: $input-grey;
    margin-top: 8px;
    font-size: 14px;
    white-space: normal;
    word-wrap: normal;

    .unapplicable-models-item-error {
      font-weight: 400;
      margin-top: -10px;
      margin-bottom: -5px;

      ul {
        list-style-type: '- ';
        padding-left: 10px;
      }
    }
  }
}

.add-course-submit-btn {
  height: 40px;
  padding: 10px 24px;
  filter: rgb(71, 126, 184);
  background-color: $primaryblue;
  border-radius: 24px;
  font-size: 16px;
  color: $white;
  margin-top: 40px;
  cursor: pointer;

  &.disabled {
    cursor: auto;
    background-color: $lightgrey;
  }

  .add-course-submit-btn-text {
    margin-left: 50%;
    transform: translateX(-20%);
  }
}

.add-course-submit-btn:hover {
  background-color: $toggle-blue;

  &.disabled {
    background-color: $lightgrey;
  }
}

.add-course-content-panel {
  width: 100%;
  padding: 24px 40px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 0 16px $dark-shadow;
  align-items: center;

  &.add-course-model-selection {
    padding: 6px 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px $dark-shadow;
  }

  &.add-course-model-selection-selected {
    background-color: rgba(205, 223, 248, 1);
    padding: 6px 12px;
    border-radius: 8px;
    height: 32px;
    box-shadow: 0px 4px 8px 0px $dark-shadow;
  }

  .tag-icon-right {
    padding-left: 4px;
  }
}

.add-course-model-text {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 700;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.collapsible-header {
  .collapsible-header-body {
    padding-left: 24px;
    padding-right: 24px;
  }

  &.medium {
    .collapsible-header-body {
      min-width: unset;
      width: 100%;
    }
  }

  &.narrow {
    .collapsible-header-body {
      min-width: unset;
      width: 800px;
    }
  }
}

.patient-info-row {
  display: flex;
  width: 100%;
  margin-top: 10px;
  font-size: 0.875rem;
  justify-content: space-between;
}

.patient-attribute-title {
  display: flex;
  flex-direction: column !important;
  font-weight: 550;
  color: $darkgrey;
  font-size: 14px;
}

.patient-attributes {
  font-size: 14px;
}

.patient-info-status {
  margin-bottom: 15px;
}

.patient-info-button {
  padding-left: 16px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  gap: 8px;
}

.patient-status-symbol {
  svg {
    border: 1px solid transparent;
    border-radius: 50%;
    box-sizing: border-box;
    fill: $loading-grey;
    box-shadow: 0px 3px 6px rgba(17, 107, 114, 0.18);
  }
}

.patient-clinicians-collapsed {
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.patient-clinicians-show-less-more {
  color: $circle-button-blue;
  cursor: pointer;
  text-decoration: underline;
  padding-left: 67px;
}

.header-action-button-padding {
  padding-top: 21px;
}

.info-display-column {
  padding: 10px;
  width: 100%;
}

.info-padded-row {
  padding-top: 12px;
}

.info-margin-right {
  display: flex;
  margin-right: 14px;
}

.col-with-dividers {
  border-right: 1px solid $darkgrey;
}

.tablet-view-first-col {
  position: relative;
  display: flex;
  padding: 10px 10px 10px 30px;
  width: 49.4%;
}

.tablet-view-second-col {
  border-left: 1px solid $darkgrey;
  position: relative;
  flex: 1;
  padding: 10px;

  .tablet-view-second-col-info {
    position: relative;
  }
}

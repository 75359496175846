@import '@doseme/cohesive-ui/dist/style/colors.scss';

.changelog-page {
  width: 640px;
  margin: auto;

  .changelog-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .changelog-title {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
    }

    .changelog-back-btn {
      display: inline-block;
      margin-right: 16px;
    }
  }

  .changelog-panel {
    border-radius: 24px;
    background-color: $white;
    height: 520px;
    padding: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    position: relative;

    .changelog-container {
      position: relative;
      height: 440px;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
      background-color: $neutrals-50;

      .change-log-end-of-scroll {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 16px;
        justify-content: center;
        display: flex;

        .change-log-end-reached-message {
          color: $neutrals-500;
        }

        .fa-chevron-up {
          padding-right: 8px;
        }
      }

      .change-log-loading-container {
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .change-log-entries-container {
        padding-bottom: 50px;

        .change-log-seperator {
          width: 100%;
          padding: 16px 0px;
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .change-log-seperator-text {
            padding: 0px 8px;
            color: $blue-sky-500;
            font-size: 14px;
            font-weight: 700;
            min-width: 190px;
          }

          .change-log-seperator-line {
            height: 1px;
            width: 100%;
            background-color: $blue-sky-500;
          }
        }
      }
    }
  }
}

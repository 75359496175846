@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-clinician-list-page {
  max-width: 1080px;
  width: auto;
  padding: 0px 24px 0px 24px;
  margin: auto;

  .clinician-list-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .clinician-list-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .clinician-list-title {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
    }

    .create-new-clinician-btn {
      margin-left: 40px;
    }
  }

  .clinician-list-content-panel {
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;

    .clinician-list-filter-search-bar-container {
      width: 100%;
      min-height: 64px;
      background-color: $menu-grey;
      border-radius: 12px;
      padding: 12px 3px 12px 16px;
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: center;

      .clinician-list-search-bar {
        padding-left: 16px;
        width: 250px;
      }

      .clinician-list-filter {
        flex-wrap: wrap;
        height: fit-content;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 8px;
        border-right: 1px solid $neutrals-400;
        padding-right: 16px;
      }
    }

    .clinician-list-list {
      margin-top: 8px;

      .clinician-list-action-button {
        padding: 5px;
      }

      .co-smart-list-row {
        overflow-wrap: anywhere;
        padding: 0px;
      }

      .co-smart-list-empty {
        padding-left: 16px;
      }

      .co-smart-list-header {
        padding-left: 0px;
      }

      &.disabled {
        .co-smart-list-header {
          background-color: $grey !important;
        }
      }
    }
  }
}

.clinician-pagination-panel-outer {
  display: flex;
  position: relative;

  .clinician-pagination-panel {
    margin-top: 12px;
    display: flex;
    position: absolute;
    right: 0px;
  }

  .display-selected-rows-btns {
    margin-top: 15px;

    .display-selected-rows-count {
      color: $bright_blue;
    }

    &.multiple {
      display: flex;
      .align-items-center {
        width: 200px;
      }
    }
  }

  .hold-space-for-pagination {
    height: 47px;
    width: 235px;
  }
}

.clinicians-name {
  display: flex;
  align-items: center;

  .clinicians-tag {
    margin-left: 8px;
    overflow-wrap: normal;

    &.admin-tag-text {
      .tag-text {
        color: $white;
      }
    }
  }
}

.doserecommendation-pk-parameters-title-individual {
  width: 30% !important;
  margin-left: 120px !important;
}

.doserecommendation-pk-parameters-individual {
  width: 30% !important;
}

.doserecommendation-pk-parameters-title-population {
  width: 30% !important;
  margin-left: 8px !important;
}

.doserecommendation-pk-parameters-population {
  width: 30% !important;
}

.doserecommendation-pk-parameters-name {
  display: flex;
}

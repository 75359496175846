@import '@doseme/cohesive-ui/dist/style/colors.scss';

.duplicate-hospital-page {
  width: 640px;
  margin: auto;

  .duplicate-hospital-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .duplicate-hospital-title {
      display: inline-block;
      font-size: 24px;
    }

    .duplicate-hospital-sub-title {
      font-size: 16px;
      font-weight: 700;
      margin-top: 8px;
    }

    .duplicate-hospital-back-btn {
      display: inline-block;
      margin-right: 16px;
    }
  }

  .duplicate-hospital-content-panel {
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;

    .duplicate-hospital-content-title {
      font-weight: 700;
      font-size: 22px;
    }

    .duplicate-hospital-inputs-wrapper {
      width: 360px;
      margin: auto;

      .duplicate-hospital-name-input {
        margin-top: 40px;
      }

      .duplicate-hospital-info-list {
        margin-bottom: 8px;
        margin-top: 8px;
        color: black;
      }

      .duplicate-hospital-submit-btn {
        margin-top: 32px;
        width: 100%;
        justify-content: center;
        margin-bottom: 16px;

        .spinner-display {
          top: 1px;
        }
      }
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-vendor-site-show-page {
  max-width: 1080px;
  width: auto;
  margin: auto;

  .side-panel-title {
    font-size: 20px !important;
  }

  .vendor-site-show-back-btn {
    display: inline-block;
    margin-top: 40px;
    margin-right: 16px;
  }

  .vendor-site-show-title {
    display: inline-block;
    margin-top: 48px;
    font-size: 24px;
  }

  .vendor-site-subtitle {
    margin-bottom: 24px;
    margin-left: 64px;
    font-size: 16px;
    font-weight: 700;
  }

  hr {
    border-top: 1px solid $lightgrey;
  }

  .side-panel-children {
    width: 1016px;
  }
}

.display-formatted-details {
  margin-top: 28px;
}

/* Global style rules */

@import '@doseme/cohesive-ui/dist/style/colors.scss';

html {
  min-height: 100vh;
}

body {
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-size: 100%;
  background-color: $snow;
}

div#root {
  height: 100%;
}

a,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: inherit;
}

.page-content {
  margin-top: 20px;
  width: 1200px;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
}

// react Toastify styles
.Toastify__toast-container {
  position: fixed;
}

.Toastify__toast-container--top-right {
  top: 1em;
}

.Toastify__toast--success {
  background: rgb(83, 171, 97);
}

.Toastify__toast--error {
  background: rgb(215, 87, 69);
}

.Toastify__toast-container--top-right div .col-icon {
  margin-top: -2%;
}

// ensure DatePickerInput displays over MessageOverlay
.react-datepicker-popper {
  z-index: 110;
}

.ife-basic-list-cell-height {
  height: 32px;
}

.doserecommendation-pk-parameters-title {
  &-individual {
    margin-left: 116px;
  }

  &-population {
    margin-left: 16px;
  }
}

.doserecommendation-pk-parameters {
  &-name {
    width: 120px;
  }

  &-individual {
    width: 80px;
  }

  &-population {
    width: 80px;
    margin-left: 10px;
  }
}

.font-bold {
  font-weight: bold !important;
}

.font-italic {
  font-style: italic;
}

.dark-label-text {
  font-weight: 550;
  color: $darkgrey;
}

.panel-error-overlay-text {
  max-width: 450px;
}

.overlay-message-button {
  width: fit-content;
  margin-top: 12px;
  flex-direction: row;
  display: flex;
}

/* IE11 fixes */
.ie11-block-contains-flexbox-bugfix {
  width: 100%;
}

.content-panel-with-shadow {
  width: 100%;
  padding: 20px 28px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 0 16px $dark-shadow;

  &.narrow {
    width: 800px;
  }

  &.add-course {
    padding: 24px 32px;
  }

  &.recorded-course-data-table {
    padding: 20px 28px;
  }

  .content-panel-with-shadow-title {
    font-size: 20px;
    font-weight: 700;
  }

  .co-basic-list-header {
    padding-right: 15px;
  }

  .co-overlay-content {
    width: 100%;
  }
}

.selectable-checkbox {
  display: flex;
  cursor: pointer;

  #checkbox-round {
    width: 16px;
    height: 16px;
    background-color: $input-grey;
    border-radius: 50%;
    vertical-align: middle;
    border: 3px solid $input-grey;
    appearance: none;
    margin-right: 8px;
    cursor: pointer;
  }

  #checkbox-round:checked {
    background-color: #438be9;
    cursor: pointer;
  }

  .disabled {
    color: $darkgrey;
  }
}

.font-14-weight-700 {
  font-size: 14px;
  font-weight: 700;
}

.font-16-weight-700 {
  font-size: 16px;
  font-weight: 700;
}

.font-black {
  color: $black;
}

.account-locked-down {
  margin-top: 16px;

  .co-information-bubble-error {
    background-color: $red-100;
    border-color: $red-300;

    .error-title {
      color: $error-red;
      font-weight: 700;

      .error-text {
        margin-top: 8px;
        color: $gunmetal;
        font-weight: 400;
      }
    }
  }
}

.account-locked-down-warning {
  margin-top: 16px;

  .co-information-bubble-error {
    border-color: $red-300;

    .error-title {
      color: $error-red;
      font-weight: 700;

      .error-text {
        margin-top: 8px;
        color: $gunmetal;
        font-weight: 400;
      }
    }
  }
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.add-administration-modal {
  display: flex;

  .admin-panel-infusion-length-input {
    width: 135px;
  }

  .molecule-picker-input {
    width: 135px;
    margin-right: 15px;

    .co-input {
      width: 90px;
    }
  }

  .admin-panel-dose-amount-input {
    width: 135px;
    margin-right: 20px;

    &.long-units {
      margin-right: 50px;
    }

    .co-input {
      width: 90px;
    }
  }

  .admin-date-time-inputs {
    display: flex;

    .date-input {
      width: 148px;

      .co-form-field-label {
        overflow: visible;
        white-space: nowrap;
      }
    }

    .time-modal-input {
      margin-right: 20px;
      width: auto;
      margin-left: 8px;
    }
  }
}

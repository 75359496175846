@import '@doseme/cohesive-ui/dist/style/colors.scss';

.TDI-info-modal {
  .message-box {
    padding: 0px !important;
  }

  .TDI-info-message {
    padding-top: 8px;
    padding-left: 24px;
    color: $charcoal-grey;
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invalid-link-page-panel {
  .invalid-link-title {
    color: $doseme-warning;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .invalid-link-body {
    margin-top: 8px;
    font-size: 14px;
  }

  .invalid-link-info-bubble {
    margin-top: 24px;
  }
}

.reset-password-page {
  .login-page-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .reset-password-page-number {
      display: flex;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: $blue-sky-400;
      font-size: 16px;
      color: $neutrals-50;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
  }

  .reset-code-input-title {
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-top: 32px;
  }

  .reset-password-error {
    margin-top: 32px;
  }

  .reset-code-inputs-outer {
    display: flex;
    margin-top: 16px;
    justify-content: center;
  }

  .login-page-password-label {
    margin-top: 8px;
  }

  .reset-password-validation {
    display: flex;
    width: 344px;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    background-color: $input-grey;
    border-radius: 8px;

    .reset-password-validation-title {
      font-weight: 700;
      font-size: 14px;
    }

    .reset-password-validation-row {
      display: flex;
      flex-direction: row;
      margin-top: 8px;

      .reset-password-validation-text {
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.login-page-panel {
  border-radius: 24px;
  background-color: $white;
  box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
  margin-top: 64px;
  padding: 40px 48px;
  margin-left: auto;
  margin-right: auto;
  color: $charcoal-grey;

  .co-validation-message {
    display: none;
  }

  .co-btn-md {
    border-radius: 40px !important;
  }

  &.larger-width {
    width: 640px;
  }

  &.smaller-width {
    width: 440px;
  }

  &.password-panel {
    padding: 24px 48px 40px 48px;
  }

  .login-page-title {
    color: $doseme-blue;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .login-page-subtitle {
    font-size: 16px;
    color: $gunmetal;
    font-weight: 700;
  }

  .login-page-email-input-label {
    margin-top: 32px;
    font-weight: 700;
    font-size: 14px;
    color: $charcoal-grey;
  }

  .login-page-password-back-arrow {
    font-size: 14px;
    color: $navy;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    margin-bottom: 24px;
  }

  .login-page-password-subtitle {
    font-size: 16px;
    margin-bottom: 40px;
    color: $gunmetal;

    width: fit-content;
    height: 24px;
    top: 100px;
    left: 48px;
    padding: 4px 8px 4px 8px;
    gap: 8px;
    border-radius: 4px;
    background-color: $input-grey;

  }

  .login-page-forgot-password-link {
    width: fit-content;
    display: block;
    padding-top: 4px;

    .login-page-forgot-password {
      font-size: 14px;
      color: $navy;
      text-decoration: underline;
      cursor: pointer;
      width: fit-content;
    }
  }

  .login-page-forgot-password-link-disabled {
    pointer-events: none;

    .login-page-forgot-password {
      color: $neutrals-600;
    }

    .co-btn-md {
      border-radius: 40px !important;
    }
  }

  .btn-primary-alt {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .login-page-password-label {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    .login-page-email-input-label {
      margin-top: 0px;
    }

    .login-page-show-password {
      font-size: 14px;
      display: flex;
      cursor: pointer;

      .login-page-show-password-label {
        margin-top: 1px;
        color: $charcoal-grey;
        font-weight: 400;
      }
    }
  }

  .login-page-fields-section {
    width: 340px;
    margin: auto;
  }
}

.sso-page-panel{
  height: 314px;

  .sso-loading-spinner {
    justify-content: center;
    display: flex;
    margin-top: 80px;
  }
}

.login-page-submit-btn-loading-style {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: $neutrals-500;
  display: flex;
}

.w-440 {
  width: 440px;
}

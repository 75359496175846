@import '@doseme/cohesive-ui/dist/style/colors.scss';

.dosingProfilePlotReferenceLineLabel {
  font-size: small;
}

.dosingProfilePlotPlotReferenceLineLabelCurrentDate {
  @extend .dosingProfilePlotReferenceLineLabel;
  color: $loading-grey;
  fill: $loading-grey;
  font-size: xx-small;
  font-weight: bolder;
  z-index: -1;
}

.secr-info-labels {
  display: flex;
  flex-direction: row;
  position: absolute;
  flex: 1;
  left: 48px;
  bottom: 128px;
  z-index: 10;

  .secr-no-info-label {
    background-color: $loading-grey !important;
  }

  .secr-info-label {
    height: 18px;
    background-color: $skyblue;
    padding: 2px 4px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    margin-right: 4px;

    .secr-info-label-title {
      font-size: 12px;
      font-weight: 700;
    }

    .secr-info-label-value {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.mic-info-label {
  display: flex;

  .mic-info-label-title {
    font-size: 12px;
    font-weight: 700;
  }

  .mic-info-label-value {
    font-size: 12px;
    font-weight: 400;
  }
}

.dosing-profile-plot-graph {
  .dosing-profile-plot-graph-standard {
      height: 334px;
  }

  .dosing-profile-plot-graph-standard-GCSF {
    height: 234px;
  }

  .dosing-profile-plot-graph-standard-secr {
    height: 184px;
  }

  .dosing-profile-plot-reset-button {
    position: absolute;
    top: 10px;
    right: 30px;
  }

  .recharts-text {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.dosing-profile-plot-graph-secr {
  height: 155px;
  width: 100%;

  .recharts-tooltip-wrapper {
    display: none;
  }
}

.dosing-profile-plot-graph-GCSF {
  height: 100px;
  width: 100%;

  .recharts-surface {
    overflow: visible;
  }
}

.dosing-profile-plot-seperator {
  height: 5px;
  margin-left: 44px;
  margin-right: 9px;
  margin-top: -10px;
  margin-bottom: -5px;
  background-color: $lightgrey;
  border-width: 0px;
  position: relative;
  z-index: 10;
}

// Recharts CSS overrides
.dosing-profile-plot-graph .recharts-text {
  color: $charcoal-grey !important;
  font-family: Arial, sans-serif !important;
}

.dosing-profile-plot-graph .recharts-label {
  font-weight: normal !important;
  font-size: 13px;
}

.dosing-profile-plot-graph .recharts-cartesian-axis-tick-value {
  font-family: Arial, sans-serif !important;
  font-weight: lighter !important;
  font-size: x-small !important;
}

.dosing-profile-plot-graph .recharts-brush-texts {
  color: $charcoal-grey !important;
  font-size: 0 !important;
}

.dosing-profile-plot-graph .recharts-tooltip-label {
  font-size: small !important;
}

.dosing-profile-plot-graph .recharts-tooltip-wrapper {
  z-index: 15;
}

.dosing-profile-plot-graph .recharts-tooltip-item-list {
  font-size: x-small !important;
}

.dosing-profile-plot-graph .custom-tooltip-outer {
  width: auto;
  height: auto;
  background-color: $snow;
  border-radius: 4px;
  border: 1px solid $loading-grey;
  font-size: 11px;
  padding: 4px 6px;

  .time-title {
    font-weight: 400;
  }

  .population-value {
    margin-top: 2px;
    color: $faded-red;
  }

  .individualized-value {
    margin-top: 2px;
    color: $ocean-blue;
  }

  .custom-value {
    margin-top: 2px;
    color: $emerald;
  }

  .guideline-value {
    margin-top: 2px;
    color: $magenta;
  }

  .GCSF-value {
    margin-top: 2px;
  }
}

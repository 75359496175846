@import '@doseme/cohesive-ui/dist/style/colors.scss';

.report-gen-preview-icon {
  padding-left: 8px;
  transform: rotate(45deg);
  bottom: 4px;
  position: relative;
}

.report-gen-info {
  padding-bottom: 24px;
}

.report-gen-content {
  flex-direction: row;
  display: flex;
  width: 100%;
}

.report-gen-content-stats {
  min-width: 344px;
}

.report-gen-content-notes {
  padding-left: 24px;
  width: 100%;
}

.report-gen-nextdose {
  font-size: 14px;
  margin-bottom: 16px;
}

.report-gen-info-header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}

.report-gen-notes-header {
  margin-bottom: 16px;
  align-items: center;
  display: flex;
}

.report-gen-regimen,
.report-gen-target-panel,
.report-gen-predicted-panel {
  border-radius: 16px;
  padding: 16px;
  font-size: 14px;
  width: 100%;
}

.report-gen-regimen {
  background-color: $pale-orange;
  margin-bottom: 8px;

  .report-gen-header {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.report-gen-target-panel {
  flex: 1 1 auto;
  margin-right: 4px;
  background-color: $menu-grey;
}

.report-gen-predicted-panel {
  flex: 1 1 auto;
  margin-left: 4px;
  background-color: $pale-blue;
}

.report-gen-attribute:not(:last-child) {
  margin-bottom: 16px;
}

textarea.clinical-notes {
  outline: none;
  border: 1px solid $lightgrey;
  height: 128px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-family: 'Arial';
  width: 100%;
  resize: none;
}

.report-preview-popup-modal {
  .regular-overlay {
    z-index: 1100;
  }

  .overlay {
    background-color: unset;
  }
}

/* overrides info-modal UI class */
.report-preview-content-wrapper .info-modal {
  min-width: 1080px;

  .message-box {
    overflow-y: auto;
  }
  /* FIXME IFE-639 - Add additional 'tablet' classes to handle when the viewport is < 1080px.  You will need to perform
      some kind of scaling transformation to the entire report preview - and even then, the font should also be reduced.
   */

  .dose-report-preview-panel,
  .dose-report-preview-panel-loading {
    height: 550px;
    width: 100%;
  }

  .dose-report-preview-panel-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dose-report-preview-panel {
    border: none;
    min-width: 966px;
  }

  .dose-report-preview-panel-epic {
    height: 550px;
    white-space: pre-line;
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.patient-preview-bottom-wrapper {
  height: 456px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.patient-preview-course-preview-bottom {
  width: 100%;
  border: 1px solid $toggle-grey;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;

  .patient-preview-admin-obs-header {
    font-size: 14px;
    font-weight: 700;
    color: $charcoal-grey;
    margin-bottom: 4px;
  }

  .patient-preview-admin-obs-details {
    margin-bottom: 12px;
    border: 1px solid $toggle-grey;
    border-radius: 8px;

    .measurement-time-header {
      white-space: nowrap;
    }

    .co-basic-list {
      box-shadow: none;
    }
  }
}

.patient-preview-course-preview-top {
  width: 100%;
  height: 60px;
  border: 1px solid $toggle-grey;
  background: $menu-grey;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  .patient-preview-course-header {
    font-size: 14px;
    font-weight: 700;
    color: $charcoal-grey;
    display: flex;

    .tag {
      border: 1px solid $red-300;
    }
  }
}

.preview-patient-bottom-panel-empty {
  width: 100%;
  height: 60px;
  border: 1px solid $toggle-grey;
  background: $menu-grey;
  border-radius: 8px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  .preview-patient-bottom-panel-empty-text {
    font-size: 14px;
    font-weight: 700;
    color: $charcoal-grey;
  }
}

.patient-preview-link-to-profile {
  display: inline-flex;
  padding: 8px 40px;
  border-radius: 24px;
  border: 1px solid $toggle-grey;
  background: $menu-grey;
  font-size: 14px;
  margin-top: 8px;

  .patient-preview-link-to-profile-count {
    padding-right: 8px;
  }

  .patient-preview-link-to-profile-text {
    color: $navy;
  }
}

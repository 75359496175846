@import '@doseme/cohesive-ui/dist/style/colors.scss';

.simulate-hd-schedule-chevron {
  color: $darkgrey;
  position: absolute;
  margin-top: 3px;
  right: 0px;
}

.scheduled-hd-sessions-headings {
  margin-top: 24px;
  display: flex;
  color: $darkgrey;
  margin-bottom: 4px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  .scheduled-hd-sessions-start-time {
    position: absolute;
    left: 222px;
    width: 80px;
  }

  .scheduled-hd-sessions-duration {
    width: 128px;
    position: absolute;
    margin-left: 310px;
  }
}

.schedule-hd-sessions-section {
  display: flex;
  height: 50px;
  width: auto;
  background-color: $blur-shadow;
  border-radius: 4px;
  padding: 8px;

  .hd-sessions-selection-div {
    display: flex;
    cursor: pointer;
    height: 16px;
    margin-top: 8px;

    .simulate-hd-sessions-checkbox {
      cursor: pointer;
      height: 12px;
      width: 12px;
    }

    .hd-sessions-day {
      font-size: 14px;
      margin-left: 8px;
      font-weight: 700 !important;

      &.disabled {
        color: $darkgrey;
      }
    }

    .hd-sessions-date {
      font-size: 14px;
      margin-left: 8px;
      font-weight: 400;

      &.disabled {
        color: $darkgrey;
      }
    }
  }

  .hd-sessions-start-time {
    margin-top: -25px;
    position: absolute;
    left: 222px;
    width: 80px;

    .co-input {
      width: 80px;
    }
  }

  .hd-sessions-duration {
    width: 128px;
    height: 34px;
    position: absolute;
    margin-left: 302px;
    margin-top: -9px;
  }
}

.validation-message {
  display: flex;
  margin-left: 223px;
  line-height: 14px;
}

.simulate-hd-sessions-info-modal {
  width: 560px;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.simulate-GCSF-chevron {
  color: $darkgrey;
  position: absolute;
  margin-top: 3px;
  right: 0px;
}

.simulate-GCSF-padding {
  padding-bottom: 24px;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.change-log-entry-panel {
  width: 472px;
  border-radius: 16px;
  padding: 16px;
  background-color: $white;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: $neutrals-900;

  .change-log-entry-date-and-icon {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
  }

  .change-log-entry-bold {
    font-weight: 700;
    white-space: nowrap;
  }

  .change-log-entry-details {
    border-left: 2px solid $neutrals-100;
    padding-left: 20px;
    margin-left: 12px;

    .change-log-entry-clinician {
      margin-bottom: 8px;
    }

    .change-log-entry-target {
      background-color: $neutrals-100;
      padding: 8px 12px;
      border-radius: 4px;
      margin-bottom: 8px;
    }

    .change-log-entry-values-container {
      padding: 4px 12px;
      gap: 8px;
      display: flex;
      flex-direction: column;

      .change-log-entry-value {
        color: black;
        font-size: 13px;
        flex-direction: row;
        display: flex;

        .change-log-entry-bullet-point {
          color: $neutrals-400;
          font-size: 7px;
          margin-right: 20px;
          line-height: 16px;
          padding-top: 4px;
        }

        .change-log-entry-value-text {
          margin: 0px;
          line-height: 16px;

          .change-log-entry-value-text-edit-label {
            font-weight: 700;
            color: $neutrals-700;
          }

          .change-log-entry-value-text-edit-from {
            color: $neutrals-600;
          }
        }
      }
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.patient-preview-panel-top-section-tooltip-menu {
  position: absolute;
  min-width: 250px;
  z-index: 100;
  right: 20px;
  top: 66px;
}

.patient-preview-none-selected {
  .co-panel-header {
    background-color: $input-grey !important;
    color: $charcoal-grey;
  }

  .patient-title {
    color: $charcoal-grey;
    font-weight: 400 !important;
  }
  
  .patient-id-container {
    color: $grey;
  }
  
  .patient-info-container {
    color: $grey;
  }
}

.patient-details {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;

  .patient-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .patient-title {
      display: flex;
      flex-direction: row;
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 4px 0;
      overflow: hidden;

      .patient-title-ellipsis-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    
    .patient-preview-button {
      display: flex;
      padding-left: 8px;
    }
  }

  .patient-id-container {
    padding-bottom: 12px;
  }

  .patient-info-container {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-bottom: 8px;

    .patient-info-label {
      font-weight: 700;
    }

    .patient-info {
      margin-right: 32px;
    }
  }
}

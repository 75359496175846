@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-hospital-drug-settings-list-page {
  max-width: 1080px;
  width: auto;
  padding: 0px 24px 0px 24px;
  margin: auto;

  .hospital-drug-settings-list-dropdown-tooltip {
    position: relative;
    min-width: 285px;
    left: -265px;
    top: 10px;
  }

  .hospital-drug-settings-list-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .hospital-drug-settings-list-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .hospital-drug-settings-list-title {
      display: inline-block;
      font-size: 24px;
    }

    .create-new-hospital-drug-settings-btn {
      margin-left: 40px;
    }
  }

  .hospital-drug-settings-list-content-panel {
    width: 100%;
    height: 852px;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;
    position: relative;

    .hospital-drug-settings-list-search-bar-container {
      width: 100%;
      height: 64px;
      background-color: $menu-grey;
      border-radius: 12px;
      padding: 12px 16px;
      display: flex;
      position: relative;

      .hospital-drug-settings-list-search-bar {
        position: absolute;
        right: 0px;
      }
    }
  }

  .hospital-drug-settings-pagination-panel {
    margin-top: 10px;
    position: absolute;
    right: 20px;
  }

  .hospital-drug-settings-list-action-button {
    padding: 5px;
  }
}

.hospital-add-drugs-filter-checkbox {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -18px;
  position: relative;
  z-index: 100;

  .hospital-add-drugs-filter-checkbox-input {
    width: fit-content;
    align-items: center;
    display: flex;
  }
}

.hospital-add-drugs-selected-model {
  font-size: 14px;
  height: 32px;
  background-color: $menu-grey;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding-left: 12px;
  margin-bottom: 4px;
}

.hospital-add-drugs-selected-model-icon-button {
  margin-left: auto;
  color: $loading-grey;
}

.hospital-add-drugs-selected-model-subtitle {
  font-size: 14px;
  margin-bottom: 8px;
}

.hospital-add-drugs-selected-items {
  overflow: auto;
  max-height: 200px;
  width: 100%;
  position: relative;
}

.delete-drug-modal-subtitle {
  font-size: 16px;
  font-weight: 700;
}

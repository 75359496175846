@import '@doseme/cohesive-ui/dist/style/colors.scss';

.tos {
  height: 60px !important;
  padding: 16px 24px !important;
  margin-top: 32px !important;
  font-size: 12px;
  font-weight: 700;

  .highlight {
    color: $faded-red;
    cursor: pointer;
  }
}

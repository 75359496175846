@import '@doseme/cohesive-ui/dist/style/colors.scss';

.twofa-page-modal {
  .twofa-page-modal-warning {
    border-radius: 6px;
    background-color: rgba(227, 152, 38, 0.18);
    padding: 8px;
    font-size: 13px;
    display: flex;
    margin-top: 16px;
  }

  .twofa-page-modal-subtitle {
    font-size: 14px;
    font-weight: 700;
    margin-top: 8px;
    color: $charcoal-grey;
  }

  .twofa-page-modal-description {
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 14px;
    color: $charcoal-grey;
  }

  .twofa-page-modal-description-text {
    color: $charcoal-grey;
  }
}

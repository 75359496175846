@import '@doseme/cohesive-ui/dist/style/colors.scss';

.drug-information-floating-tab-bar {
  margin-bottom: 40px;
}

.accordion-child-data-wrapper {
  font-size: 14px;
}

.drug-information-spinner {
  position: relative;
  margin-left: 46%;
}

.drug-information-accordion {
  margin-top: 0.5rem;
  a {
    color: $link-text !important;
    text-decoration: underline;
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.custom-docetaxel-sub-tab {
  width: 320px;
}

.custom-docetaxel-calculate {
  display: flex;
  background-color: $blue-steel-100;
  height: 110px;
  border-radius: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;

  .co-information-bubble {
    background-color: white;
    width: 220px;

    .custom-docetaxel-calculate-info-text {
      color: black;
      margin: 0px;
      padding-left: 24px;
    }
  }
}

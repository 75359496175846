@import '@doseme/cohesive-ui/dist/style/colors.scss';

.help-request-title {
  font-size: 18px;
  margin-bottom: 8px;
}

.help-request-subtitle {
  font-size: 14px;
  margin-bottom: 36px;
}

.help-request-patient-details {
  border-left: 3px solid $action-yellow;
  font-size: 22px;
  padding-left: 24px;
  margin-bottom: 36px;
}

.help-request-confirmation-message {
  font-size: 14px;
  line-height: 14px;
}

.start-chat-button-cerner {
  background-color: #477eb8;
  color: white;
  padding: 12px;
  border: 1px solid;
  align-items: center;
  border-radius: 5px;
  height: 16px;
  display: flex;
}

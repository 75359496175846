@import '@doseme/cohesive-ui/dist/style/colors.scss';

.drug-settings-hospital-list-page{
  width: 576px;
  margin: auto;

  .drug-settings-hospital-list-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .drug-settings-hospital-list-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .drug-settings-hospital-list-title {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .drug-settings-hospital-list-subtitle {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
  }

  .drug-settings-hospital-list-content-panel {
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;

    .drug-settings-hospital-list-search-bar-container {
      width: 100%;
      height: 64px;
      background-color: $menu-grey;
      border-radius: 12px;
      padding: 12px 16px;
      display: flex;
      position: relative;
      align-items: center;
    }

    .drug-settings-hospital-list-list {
      margin-top: 8px;
    }
  }
}

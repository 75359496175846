@import '@doseme/cohesive-ui/dist/style/colors.scss';

.patient-list-pagination-panel {
  margin-top: 20px;
  justify-content: space-between;
  display: flex;

  .archive-buttons-count {
    color: $bright_blue;
  }
}

.patient-list-hide-bottom-border {
  .co-smartlist-bottom-rounded-corners {
    border: 0px;
  }
}

.patient-list-filtered-out-container {
  width: 100%;
  justify-content: center;
  display: flex;

  .patient-list-filtered-out-message {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-radius: 24px;
    color: $white;
    gap: 16px;
    background-color: $darkgrey;
  }

  .patient-list-filtered-out-message-bold {
    font-weight: 700;
  }
}

.patient-list-smartlist {
  .co-smart-list-row {
    padding: 0px;
  }

  .co-smart-list-empty {
    padding-left: 16px;
  }

  .co-smart-list-header {
    padding-left: 0px;
  }
}

.patient-preview-loading-state {
  height: 561px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient-preview {
  height: 644px;

  .co-panel .co-panel-section {
    padding: 0px;
  }

  .co-with-border {
    border-top-width: 0px !important;
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.note-bubble {
  .note-bubble-text-collapsed {
    max-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .note-bubble-show-less-more {
    color: $circle-button-blue;
    cursor: pointer;
    text-decoration: underline;
  }
}

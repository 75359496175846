@import '@doseme/cohesive-ui/dist/style/colors.scss';

.dosing-profile-plot-selector {
  padding-left: 15px;
  padding-right: 5px;
  border-radius: 8px;
  border: 1px solid $lightgrey !important;

  position: absolute;
  top: -50px;
  right: -1px;
  z-index: 50;
  background-color: $white !important;
  margin-right: 8px;

  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  .header {
    font-weight: bold;
  }

  input[type='checkbox'] {
    margin-left: 7px;
  }

  .dialysis-icon {
    display: inline-flex;
    height: 12px;
    width: 12px;
    padding: 0px;
    background-color: $light-blue;
  }

  .co-accordion-child {
    min-width: 165px !important;
    border: 1px !important;
    background-color: $white !important;

    .accordion-child-data {
      font-size: 14px;
      padding: 10px !important;
      margin-top: -10px;

      .header {
        padding-top: 10px;
      }
    }
  }

  .co-accordion-child-data {
    padding: 0px !important;
    background-color: $white !important;
  }

  .accordion {
    font-size: 14px !important;
    padding: 0px !important;
    margin-top: 8px !important;
    background-color: $white !important;
    height: auto !important;

    div {
      padding: 0px !important;
    }

    svg {
      transform: scale(1) !important;
    }
  }

  .accordion-chevron {
    position: relative !important;
    left: 55px !important;
    top: -4px !important;
    transform: inherit !important;
    margin-left: 25px !important;
  }

  .plotSelection-indent {
    padding-right: 22px;
    display: inline-block;
  }

  .plotSelection-label {
    font-weight: normal;
    min-width: 80px;
    display: inline-block;
  }

  .plotSelection-checkbox-and-radio {
    margin: 0px 3px 0px 7px;
  }

  .plotSelection-dash {
    font-weight: bold;
    font-size: initial;
    width: 16px;
    position: absolute;
    right: 10px;

    &.individualized {
      color: $ocean-blue;
    }

    &.population {
      color: $faded-red;
    }

    &.guideline {
      color: $magenta;
    }

    &.customized {
      color: $emerald;
    }
  }
}

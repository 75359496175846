.edit-dose-date-input{
  width: 148px;
  
  .co-form-field-label {
    overflow: visible;
    white-space: nowrap;
  }
}

.edit-dose-time-input {
  margin-right: 20px;
  width: auto;
}

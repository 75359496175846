.edit-observation-date-input{
  width: 148px;
  
  .co-form-field-label {
    overflow: visible;
    white-space: nowrap;
  }
}

.edit-observation-time-input {
  margin-right: 20px;
  width: auto;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.change-drug-model-form {
  justify-content: space-between;
  width: 100%;

  .co-validation-message {
    padding-bottom: 0px;
    height: 0px;
  }
}

.applicable-models-border {
  border: 2px solid $neutrals-200;
  border-radius: 16px;
  padding: 12px 16px;

  &.better-models {
    border: 2px solid $amber-300;
    margin-top: 16px;
  }

  //FIXME: the commented out sections below were removed as a part of IFE-1311 nad will likely be re-added after
  //the logic is revised
  //.model-selection-better-model-subtitle {
  //  font-weight: 400;
  //  font-size: 14px;
  //  color: $neutrals-700;
  //  display: flex;
  //  line-height: 22px;
  //}
  //
  //.better-model-selection-box {
  //  width: 27px;
  //  height: 22px;
  //  border-radius: 8px;
  //  background-color: $loading-grey;
  //  color: white;
  //  font-weight: 700;
  //  font-size: 14px;
  //  margin-top: 13px;
  //  margin-right: 10px;
  //  text-align: center;
  //  padding-top: 3px;
  //
  //  &.selected {
  //    background-color: $blue-sky-400;
  //  }
  //}
}

.drugmodelswitch-warning {
  margin-top: 24px;
  background-color: $pale-orange;
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  line-height: 17px;

  .drugmodelswitch-warning-text {
    margin-left: 25px;
    margin-top: -22px;
  }

  .drugmodelswitch-warning-message {
    font-size: 14px;
    font-weight: 400;
  }
}

.drugmodelswitch-checkbox-text {
  font-size: 14px;
  padding-left: 5px;
  margin-top: 21px;
}

.add-course-content-panel-drugswitchmodal {
  width: 100%;
  padding: 24px 40px;
  background-color: $white;
  border-radius: 8px;
  align-items: center;
  border: 2px solid $neutrals-100;
  padding: 6px 12px;

  &.add-course-model-selection-selected {
    background-color: rgba(205, 223, 248, 1);
  }
}

.model-selection-info-text {
  position: absolute;
  right: 32px;
  margin-top: 4px;
  border-radius: 4px;
  width: 173px;
  height: 24px;
  background-color: $neutrals-100;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  padding: 4px 8px;
  display: flex;
}

.model-selection-info-icon {
  transform: translateY(-1px);
}

.model-selection-modal {
  .co-row .co-col-11 {
    padding: 0px;
    width: 100%;
  }

  .modal-subtitle {
    padding: 0px;
  }

  .info-modal-m {
    width: 560px;
  }
}

.model-selection-popup-modal {
  .regular-overlay {
    z-index: 1100;
  }

  .overlay {
    background-color: unset;
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.notes-and-activity-log {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  background-color: $snow;
  box-shadow: inset 0px 17px 20px -20px $grey;
  position: relative;

  .activity-log-store-grey-spinner {
    margin-left: 40%;
    padding-top: 50%;
  }

  .activity-log-content {
    padding-top: 24px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    height: 342px;
    position: absolute;
    top: 0px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .activity-log-entry-outer {
      width: 100%;
      padding-bottom: 24px;

      .activity-log-entry-icon {
        padding: 0px 24px 0px 12px;
        margin-bottom: 4px;

        .activity-log-entry-date {
          margin-left: 9px;
          padding-top: 4px;
          padding-bottom: 8px;
        }
      }
    }

    .activity-log-entry {
      height: auto;
      margin-top: auto;
      padding: 0px 24px 0px 23px;

      .note-bubble {
        background-color: $input-grey;
        border-radius: 0 8px 8px 8px;
        padding: 16px;
        margin-top: 4px;
        width: 100%;
        word-wrap: break-word;
        white-space: pre-line;
      }

      .activity-log-entry-border {
        border-left: 2px solid $input-grey;
      }
    }

    .activity-log-entry-comment {
      margin-left: 19px;

      .activity-log-system-message-list-entry::marker {
        color: $grey;
        font-size: 20px;
      }

      ul {
        margin-top: 0px;
        list-style: outside;
        margin-left: -19px;
        margin-bottom: 0px;
      }

      .activity-log-details-wrapper {
        position: relative;
        padding: 3px;
        border-radius: 4px;
        background-color: $input-grey;
      }
    }
  }

  .activity-log-text-input {
    background-color: $white;
    border: 1px solid $input-grey;
    position: absolute;
    bottom: 0;
    width: 328px;
    margin: 0px 16px 12px;

    &.narrow {
      width: 286px;
    }
  }

  .activity-log-course-created-title {
    font-weight: 500;
    text-align: center;
    color: $grey;
    padding-bottom: 8px;

    .activity-log-course-created-title-header {
      font-weight: 600;
    }
  }
}

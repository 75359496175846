@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-resource-page {
  max-width: 1080px;
  margin: auto;
  height: 100%;
  justify-content: center;
  align-items: center;


  .resources-page-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 48px;
    margin-bottom: 8px;
    text-align: left;
    width: fit-content;
  }

  .narrow {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .resources-page-panel-block {
    display: flex;

    .resources-panel-placeholder {
      width: 100%;
      height: 240px;
      margin-top: 16px;
      margin-left: 28px;
      padding: 20px 28px;
      position: relative;
    }

    .resources-panel {
      width: 100%;
      height: 240px;
      border-radius: 24px;
      background-color: $white;
      box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
      margin-top: 16px;
      padding: 20px 28px;
      position: relative;
      cursor: pointer;
      border-color: $white;
      border-width: 4px;
      border-style: solid;

      &:hover {
        border-color: $blue-steel-200;
      }

      .resources-panel-title {
        font-size: 20px;
        font-weight: 500;

        &.help-videos {
          color: $validgreen;
        }

        &.user-guides {
          color: $bright-blue;
        }

        &.clinical-competencies {
          color: $magenta;
        }

        &.drug-information {
          color: $ocean-blue;
        }

        &.clinical-resources {
          color: $doseme-warning;
        }

        &.compliance {
          color: $plot-individualised;
        }
      }

      .resources-panel-text {
        font-size: 14px;
        margin-top: 16px;
        line-height: 20px;
      }

      .resources-arrow-link {
        position: absolute;
        bottom: 17px;
        right: 32px;
      }
    }
  }

  .release-notes-narrow {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .release-notes {
    .release-notes-title {
      font-size: 24px;
      font-weight: 700;
      margin-top: 80px;
      margin-bottom: 16px;
    }

    .release-notes-container {
      position: relative;
      height: 100%;
      width: 100%;
      border-radius: 24px;
      overflow: hidden;
      box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
      background-color: $white
    }

    .release-notes-scrollable {
      padding: 24px 32px 56px 32px;
      max-height: 520px;
      background-color: $white;
    }

    .release-notes-temporary-panel {
      padding: 24px 32px 8px 32px;
      background-color: $white;
    }

    ul {
      padding-inline-start: 14px;
    }
  }
}

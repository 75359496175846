@import '@doseme/cohesive-ui/dist/style/colors.scss';

.add-admin-obs-panel-outer {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 16px 18px 16px !important;

  &.bottom-border {
    border-bottom: 1px solid #cccccc;
  }

  .co-validation-message {
    padding-bottom: 0px;
  }

  .add-admin-obs-panel-inner {
    width: 100%;
    display: flex;
    flex: 1;
  }

  .add-admin-obs-panel-number {
    width: 20px;
    height: 20px;
    border-radius: 40px;
    background-color: $bright-blue;
    text-align: center;
    margin-right: 16px;

    .text {
      font-weight: 700;
      font-size: 13px;
      font-family: Arial, sans-serif;
      color: $snow;
    }
  }

  .add-admin-obs-close-panel-button {
    margin-left: 16px;

    .dismiss-button {
      padding: 0px;
    }
  }
}

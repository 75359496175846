.error-page-overlay-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;

  .error-page-overlay-div > .co-overlay-container {
    .co-overlay-screen {
      .co-overlay-offset {
        width: 100%;
      }

      position: static;
      max-width: 420px;
    }

    .co-overlay-covered-content {
      display: none;
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.footer-row {
  font-size: 14px;

  a {
    color: $bgwhite;
    text-decoration: underline;
  }
}

.integrated-footer-wrapper {
  position: relative;
  display: flex;
  min-height: 124px;

  .footer-info-top-column {
    position: absolute;
    left: 00%;
  }

  .footer-info-bottom-column {
    margin-top: 25px;
    position: absolute;
    left: 70%;
    bottom: 10%;
  }
}

.footer-link {
  text-decoration: underline;
  cursor: pointer;
}

.versions-wrapper {
  &.reactive {
    margin-top: 73px;
    left: -200px;
  }

  position: absolute;
  right: 15px;
}

.display-version-information-footer {
  display: flex;
  justify-content: center;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.clinician-settings-page {
  width: 640px;
  margin-left: auto;
  margin-right: auto;

  .clinician-settings-header {
    display: flex;
    flex-direction: row;
    margin-top: 56px;
    margin-bottom: 32px;

    .clinician-settings-back-btn {
      margin-right: 16px;
    }

    .clinician-settings-title {
      display: flex;
      align-items: center;
      font-size: 24px;
    }
  }

  .clinician-settings-content-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white;
    border-radius: 32px;
    box-shadow: 0px 8px 24px 0px $dark-shadow;
    padding: 24px 32px 40px 32px;

    .clinician-settings {
      display: flex;
      flex-direction: column;
      align-items: center;

      .clinician-settings-profile-form {
        width: 360px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
        margin-bottom: 32px;
        align-items: center;

        .clinician-settings-email-title {
          font-size: 14px;
          padding-bottom: 5px;
        }

        .clinician-settings-email {
          font-size: 14px;
          padding: 11.5px 16px;
          border-radius: 8px;
          background-color: $neutrals-100;
          display: flex;
          margin-bottom: 34.5px;
        }

        .reset-password-error {
          margin-top: 32px;
        }
      }

      .clinician-settings-profile-spinner {
        margin: 40px 0px;
      }

      .btn-primary {
        width: 360px;
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
      }

      .clinician-settings-password-label {
        display: flex;
        justify-content: space-between;

        .clinician-settings-password-input-label {
          font-weight: 700;
          font-size: 14px;
        }

        .clinician-settings-password-show-password {
          font-size: 14px;
          display: flex;
          cursor: pointer;

          .clinician-settings-password-show-password-label {
            margin-top: 1px;
          }
        }
      }

      .clinician-settings-password-validation {
        display: flex;
        width: 360px;
        margin-top: 16px;
        padding: 8px 16px;
        flex-direction: column;
        align-items: flex-start;
        background-color: $input-grey;
        border-radius: 8px;

        .clinician-settings-password-validation-title {
          font-weight: 700;
          font-size: 14px;
        }

        .clinician-settings-password-validation-row {
          display: flex;
          flex-direction: row;
          margin-top: 8px;

          .clinician-settings-password-validation-text {
            font-size: 14px;
            margin-left: 6px;
          }
        }
      }
    }
  }
}

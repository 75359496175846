@import '@doseme/cohesive-ui/dist/style/colors.scss';

.patient-profile-content {
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;

  &.wide {
    max-width: 80%;
    min-width: 1220px;
  }

  &.medium {
    width: 100%;
  }

  &.narrow {
    width: 800px;
  }

  .patient-profile-back-btn {
    transform: translateY(2px);
  }
}

.course-history-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 24px;
}

.course-history-title {
  font-size: 24px;
  font-weight: normal;
  margin: 0 12px;
}

.bottom-panel-wrapper {
  height: 467px;
}

.bottom-panel-footer-link {
  display: flex;
  width: 100%;
  height: 100%;

  .bottom-panel-footer-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .bottom-panel-footer-view-all {
      color: $navy;
      margin-left: 8px;
    }

    .bottom-panel-footer-view-all-disabled {
      margin-left: 0px;
      color: $loading-grey;
    }
  }
}

.bottom-panel-footer-link-disabled {
  cursor: not-allowed;
}

.patient-profile-pagination-panel {
  margin-top: 20px;
  margin-left: 420px;
  justify-content: flex-end;
  display: flex;
}

.course-preview-loading-state {
  height: 561px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.co-panel .co-panel-section {
  padding: 20px 28px;
  overflow: hidden;
}

.course-list {
  padding: 0px !important;
  margin-right: 24px;
}

.course-preview {
  padding: 0px !important;
}

.tablet-view-course-list {
  .co-active-row:before,
  .co-active-row:after {
    border-style: none !important;
  }
}

.center-tooltip-text {
  text-align: center;
  z-index: 100;
}

.course-list-molecule-name {
  color: $charcoal-grey;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .course-list-molecule-name-ellipsis-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

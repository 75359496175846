.x-axis-title {
  font-size: 10px;
}

.historicalPlotReferenceLineLabel {
  font-size: 10px;
  transform: translateY(6px);
}

.historicalPlotReferenceLineLabelGood {
  @extend .historicalPlotReferenceLineLabel;
  color: #458833;
  fill: #458833;
}

.historicalPlotReferenceLineLabelWarning {
  @extend .historicalPlotReferenceLineLabel;
  color: #B47A23;
  fill: #B47A23;
}

.historicalPlotReferenceLineLabelBad {
  @extend .historicalPlotReferenceLineLabel;
  color: #983430;
  fill: #983430;
}

.neutropenia-grade-areas {
  width: 100%;
  padding-left: 5px;
}

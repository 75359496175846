.secr-label-title {
  font-size: 12px;
  font-weight: 500;
  font-family: Arial, sans-serif;
  font-style: normal;
}

.secr-label-sub-title {
  font-size: 11px;
  font-weight: 300;
  font-family: Arial, sans-serif;
  font-style: normal;
}

.secr-tooltip-text {
  font-size: 12px;
  font-weight: 300;
  font-family: Arial, sans-serif;
  font-style: normal;
}

.secr-tooltip-text-bold {
  font-size: 12px;
  font-weight: 500;
  font-family: Arial, sans-serif;
  font-style: normal;
}

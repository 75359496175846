.add-doses-observations-wrapper {
  .add-doses-observations-subtitle {
    margin-bottom: 16px !important;
    width: min-content;
  }
  
  .admin-obs-modal-buttons {
    width: 100%;
    height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .admin-obs-modal-add-new-button {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      top: -38px;
      width: fit-content;

      .co-action-button {
        padding: 4px 14px 9px 6px !important;
      }
    }

    .admin-obs-modal-save-button {
      margin-left: auto;
    }
  }

  .add-doses-assumed-interval-info {
    font-weight: bold;
    width: fit-content;
  }

  .message-box {
    padding: 0px !important;
    margin: 0px !important;
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-clinician-show-page {
  width: 640px;
  margin: auto;

  .clinician-details-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .clinician-details-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .clinician-details-title {
      display: inline-block;
      font-size: 24px;
    }
  }

  .enable-clinician-panel {
    background-color: darken($skyblue, 4);
    height: 48px;
    padding: 12px 32px;
    border-radius: 16px;
    position: relative;
    margin-bottom: 16px;

    .enable-clinician-slider-div {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      display: flex;

      .enable-clinician-slider {
        transform: translateY(-6px);
      }
    }
  }

  .clinician-details-content-panel {
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;
    position: relative;

    .edit-clinician-submit-btn {
      height: 40px;
      padding: 10px 24px;
      filter: rgb(71, 126, 184);
      background-color: $primaryblue;
      border-radius: 24px;
      font-size: 16px;
      color: $white;
      cursor: pointer;
      width: 360px;
      margin: auto;
      margin-top: 14px;

      &.disabled {
        cursor: auto;
        background-color: $lightgrey;
      }

      .edit-clinician-submit-btn-text {
        margin-left: 50%;
        transform: translateX(-40%);
      }
    }

    .edit-clinician-security-div {
      width: 360px;
      margin-left: 50%;
      transform: translateX(-50%);

      .security-content-div {
        .title-text {
          margin-top: 32px;
          font-size: 14px;
          font-weight: 700;
        }

        .clinician-2fa-div {
          font-size: 14px;
          font-weight: 700;
          padding: 11.5px 16px;
          border-radius: 8px;
          background-color: $light-shadow;
          display: flex;

          .clinician-2fa-symbol {
            margin-top: 1px;
            margin-left: 8px;
          }
        }

        .clinician-2fa-reset-btn {
          margin-top: 5px;
          margin-left: 16px;
        }
      }
    }

    .clinician-details-spinner {
      margin-left: 150px;
    }

    .edit-clinician-reset-btn {
      color: $lightgrey;
      cursor: pointer;
      margin-left: 240px;
      margin-top: 19px;
      font-size: 16px;
    }

    .hospital-access-action-btn {
      margin-left: 50%;
      transform: translateX(-50%);
      margin-bottom: 40px;
      margin-top: 16px;
    }
  }

  .edit-clinician-profile-form-content {
    width: 360px;
    margin: auto;

    .edit-clinician-profile-form-buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 32px;

      .btn-primary {
        width: 360px;
        display: flex;
        justify-content: center;
        margin-bottom: 8px;
      }
    }

    .clinician-settings-email-title {
      font-size: 14px;
      padding-bottom: 5px;
    }

    .clinician-settings-email {
      font-size: 14px;
      padding: 11.5px 16px;
      border-radius: 8px;
      background-color: $neutrals-100;
      display: flex;
      margin-bottom: 34.5px;
    }
  }

  .clinician-settings-inline-inputs {
    width: 172px;
  }

  .clinician-pagination-panel {
    margin-top: 20px;
    display: flex;
    position: absolute;
    right: 20px;
  }
}

.clinician-hospital-access-info-tooltip {
  text-align: center;
  line-height: 15px;
  z-index: 100;
}

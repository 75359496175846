.patient-info-name {
  font-weight: bolder;
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.patient-info-id {
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.patient-info-name-div {
  display: flex;
  max-width: 870px;
  gap: 16px;
}

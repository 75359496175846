@import '@doseme/cohesive-ui/dist/style/colors.scss';

.help-videos-video {
  iframe {
    border-radius: 16px;
  }
}

.help-videos-background {
  background-color: $toggle-selected;
  border-radius: 16px;
  padding: 20px;
}

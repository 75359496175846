@import '@doseme/cohesive-ui/dist/style/colors.scss';

.help-page-panel {
  background-color: $white;
  box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
  margin-top: 64px;
  height: 488px;
  width: 440px;
  border-radius: 0px 0px 24px 24px;

  .help-page-title-block {
    width: 440px;
    height: 108px;
    padding: 40px 48px 40px 48px;
    gap: 8px;
    border-radius: 24px 24px 0px 0px;
    color: white;
    font-weight: 700;
    font-size: 24px;
    line-height: 27.6px;
    border-bottom: solid 4px $red-500;
    background: linear-gradient(92.95deg, #165DF5 2.45%, #083AA6 84.04%, #09325F 129.93%),
      linear-gradient(95.17deg, #448FEB -21.29%, #165DF5 16.8%, #083AA6 54.89%, #29187A 92.98%, #4F31E8 119.16%);
  }

  .help-page-content-block {
    width: 440px;
    height: 376px;
    padding: 32px 44px 40px 44px;
    gap: 24px;

    .help-page-customer-subtitle{
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: rgba(9, 50, 95, 1);
    }

    .help-page-contact-subtitle{
      font-size: 14px;
      line-height: 16px;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    .help-page-contact-block {
      width: 352px;
      height: 72px;
      border-radius: 16px;
      background: $blue-steel-100;
      margin-top: 8px;
      padding: 16px;
      display: flex;

      .contact-block-icon {
        width: 40px;
        height: 40px;
        top: 16px;
        left: 20px;
        border-radius: 40px;
        background: $blue-steel-700;
        margin-right: 16px;
        padding: 12px;
      }

      .contact-block-info {
        .title {
          font-size: 12px;
          color: $neutrals-600;
          margin-bottom: 4px;
        }

        .link {
          font-size: 16px;
          line-height: 20px;
          text-decoration-line: underline;
          text-decoration-style: solid;
          color: var(--Blue-Sky-blue-sky-500, rgba(22, 93, 245, 1));
          cursor: pointer;
        }
      }
    }
  }
}

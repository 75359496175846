@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-hospital-list-page {
  max-width: 1080px;
  width: auto;
  padding: 0px 24px 0px 24px;
  margin: auto;

  .hospital-list-dropdown-tooltip {
    position: relative;
    min-width: 285px;
    left: -265px;
    top: 10px;
  }

  .hospital-list-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .hospital-list-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .hospital-list-title {
      display: inline-block;
      font-size: 24px;
      font-weight: bold;
    }

    .create-new-hospital-btn {
      margin-left: 40px;
    }
  }

  .hospital-list-content-panel {
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;

    .hospital-list-search-bar-container {
      width: 100%;
      height: 64px;
      background-color: $menu-grey;
      border-radius: 12px;
      padding: 12px 16px;
      display: flex;
      position: relative;

      .hospital-list-search-bar {
        position: absolute;
        right: 0px;
      }
    }

    .hospital-list-list {
      margin-top: 8px;

      .hospital-list-action-button {
        padding: 5px;
      }
    }

    .hospitals-features {
      display: flex;
      flex-wrap: wrap;

      .tag {
        margin-right: 8px!important;
      }
    }
  }

  .hospital-list-pagination-panel-outer {
    display: flex;
    position: relative;

    .hospital-list-pagination-panel {
      margin-top: 12px;
      display: flex;
      position: absolute;
      right: 0px;
    }

    .hold-space-for-pagination {
      height: 47px;
      width: 235px;
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.no-modal-panel-data-row {
  .no-modal-panel-title-row {
    margin-left: 16px;
    display: flex;

    .no-modal-panel-icon-outer {
      width: 65px;
      height: 24px;
      border-radius: 20px;
      background-color: $input-grey;
      margin-left: 4px;
      padding: 4px 8px;
      transform: translateY(-3px);
      display: flex;
      cursor: pointer;

      .icon-text {
        text-decoration: underline;
        margin-left: 4px;
      }
    }

    .no-modal-panel-icon {
      margin-left: 12px;
      cursor: pointer;
    }

    .no-modal-panel-data-row-column-1 {
      position: absolute;
      margin-left: 534px;

      &.narrow {
        position: relative;
        margin-left: 45px;
      }
    }

    .no-modal-panel-data-row-column-2 {
      position: absolute;
      margin-left: 712px;

      &.narrow {
        position: relative;
        margin-left: 45px;
      }
    }

    .no-modal-panel-data-row-column-3 {
      position: absolute;
      margin-left: 868px;

      &.narrow {
        position: relative;
        margin-left: 45px;
      }
    }

    .hospial-drug-details-panel-label {
      font-weight: 700;
    }

    .abs-dose-limits-color {
      color: $error-red;
    }
  }

  .no-modal-panel-data-row-help-text {
    margin-left: 36px;
    width: 400px;
  }
}

.editable-no-modal-panel-data-row {
  border-radius: 8px;
  background-color: $neutrals-100;
  margin-top: 8px;
  padding: 16px;
  width: auto;

  .co-form-field-optional-label {
    display: none;
  }

  .co-input-units {
    display: none;
  }

  .title {
    font-weight: 700;
    font-size: 16px;
  }

  .unit-outer {
    height: 24px;
    border-radius: 8px;
    padding: 3px 12px;
    background-color: $dark-shadow;
    transform: translateY(-2px);
    margin-left: 16px;
  }

  .description-text {
    margin-left: 16px;
    margin-top: 5px;
    width: 400px;
  }

  .editable-no-modal-panel-data-row-column {
    width: 64px;
    margin-top: -16px;
  }

  .editable-no-modal-panel-subtitle-row-column {
    margin-left: 55px;
    margin-right: 8px;

    &.one {
      margin-left: 109px;
    }
  }
}

.abs-dose-limits-icon-outer {
  width: 310px;
  height: 20px;
  background-color: $input-grey;
  border-radius: 4px;
  padding: 2px 4px;
  margin-left: 8px;
  display: flex;
  font-size: 14px;
  color: $charcoal-grey;
  flex-direction: row;

  .abs-limits-icon {
    margin-right: 2px;
    margin-top: 1px;
  }
}

.editable-no-modal-panel-btns {
  margin-top: 24px;
  display: flex;
}

.hospital-drug-model-settings-modal-subtitle {
  margin-top: -10px;
}

.hospital-drug-superadmin-settings-modal-data {
  height: 128px;
  padding: 8px;
  border-radius: 8px;
  background-color: $menu-grey;
  font-size: 14px;

  .data-row {
    padding: 10px 8px;
    display: flex;
    position: relative;

    .data-middle {
      position: absolute;
      left: 130px;
    }

    .data-end {
      position: absolute;
      left: 280px;
    }

    &.border {
      border-bottom: 2px solid $toggle-grey;
    }
  }
}

.hospital-drugs-target-settings-inputs {
  display: flex;
  transform: translateY(-12px);

  &.narrow {
    transform: translateY(0px);
    margin-left: 0px;
  }

  .input-container {
    display: flex;

    .input {
      display: flex;
      width: 64px;

      .input-readonly-value {
        display: flex;
        height: 40px;
        padding: 11px 0px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid $neutrals-400;
        background: $neutrals-100;
        width: 100%;
        font-size: 16px;
        color: $neutrals-600;
        margin-top: 5px;
      }
    }

    .input-title {
      margin-left: 55px;
      margin-right: 8px;
      transform: translateY(15px);

      &.narrow-one {
        margin-left: 0px;
      }

      &.one {
        margin-left: 117px;
      }
    }

    &.hidden {
      visibility: hidden;
    }
  }
}

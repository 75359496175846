@import '@doseme/cohesive-ui/dist/style/colors.scss';

.validation-error-list {
  font-size: 0.875rem;
}

.validation-error-title {
  color: $error-red
}

.validation-error-text {
  font-size: 0.875rem;
}

.add-observation-modal {
  display: flex;

  .obs-dropdown-input {
    margin-right: 15px;
    width: 164px;

    .co-dropdown-closed {
      display: flex;
      height: 40px;
      align-items: center;

      &:after {
        top: unset;
      }
    }
  }

  .obs-amount-input {
    width: 200px;

    .co-input {
      width: 90px;
    }
  }

  .obs-date-time-inputs {
    display: flex;

    .date-input {
      width: 148px;

      .co-form-field-label {
        overflow: visible;
        white-space: nowrap;
      }
    }

    .time-modal-input {
      margin-right: 15px;
      width: auto;
      margin-left: 8px;
    }
  }
}

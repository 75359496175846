@import '@doseme/cohesive-ui/dist/style/colors.scss';

.import-patient-modal-container {
  .import-patient-subtitle {
    font-weight: bold;
  }

  .filedrop-icon {
    margin-top: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
  }

  .search-local-files {
    color: $circle-button-blue;
    cursor: pointer;
    text-decoration: underline;
    display: inline-block !important;
  }

  .upload-empty {
    margin: -20px;
  }

  .replace-uploaded-file{
    color: $circle-button-blue;
    cursor: pointer;
    text-decoration: underline;
    margin: -10px;
  }
}

.drop-border {
  border: 2px dashed $neutrals-400;
  border-radius: 12px;
  margin-top: 24px;
  height: 120px;
  width: 572px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drop-border-error {
  border: 2px dashed $red-500;
}

p.upload-empty {
  color: $neutrals-400;
}

.filedrop-icon {
  margin: 9px 9px 6px 9px;
  width: 32px;
  height: 32px;
  line-height: 1;
}

.filedrop-status {
  line-height: 1;
  padding-right: 12px;
}

.errors-header {
  font-size: 18px;
}

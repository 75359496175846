@import '@doseme/cohesive-ui/dist/style/colors.scss';

.model-fit-bad {
  border: 2px solid $amber-300 !important;
  padding: 0px 4px 0px 12px !important;
}

.model-fit-disabled {
  border: 2px solid $neutrals-300 !important;
}

.model-fit-indicator {
  height: 32px;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  gap: 12px;
  border-radius: 8px;
  border: 2px solid $green-300;

  .model-fit-title {
    font-size: 14px;
    line-height: 20px;
  }

  .model-fit-title-disabled {
    color: $loading-grey;
  }

  .model-fit-info {
    margin-top: 2px;
    width: 16px;
    position: relative;
  }

  .model-fit-info-tooltip {
    max-width: 285px;
    text-align: center;
    line-height: 15px;
    display: inline-block;
  }

  .model-fit-info-button {
    margin-left: -8px;
  }

  .model-fit-info-button-tag {
    margin-left: -8px;
    margin-top: -2px;
  }
}

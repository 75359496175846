@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-add-hospital-page {
  width: 640px;
  margin: auto;



  .add-hospital-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .add-hospital-back-btn {
      display: inline-block;
      margin-right: 16px;
    }

    .add-hospital-title {
      display: inline-block;
      font-size: 24px;
    }
  }

  .add-hospital-content-panel {
    width: 100%;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;

    .add-hospital-content-title {
      font-weight: 700;
      font-size: 20px;
    }

    .add-hospital-inputs-wrapper {
      width: 360px;
      margin: auto;

      .add-hospital-name-input {
        margin-bottom: 16px;
        margin-top: 40px;
      }

      .create-new-trial-hospital-panel {
        background-color: darken($neutrals-100, 4);
        height: 48px;
        overflow: auto;
        padding-top: 6px;
        border-radius: 16px;
        margin-bottom: 32px;

        .create-new-trial-hospital-slider-div {
          position: absolute;
          padding: 12px 32px;
          left: 50%;
          transform: translateX(-50%);
          font-weight: 700;
          display: flex;
          width: 360px;
          font-size: 14px;
          justify-content: center;

          .create-new-trial-hospital-slider {
            transform: translateY(-10px);
          }
        }

        .trial-hospital-units-label {
          justify-content: center;
          position: absolute;
          width: 328px;
          margin-left: 18px;
          margin-top: 40px;
        }
      }

      .expanded-create-new-trial-hospital-panel {
        height: 120px;
      }

      .add-hospital-submit-btn {
        margin-top: 16px;
        width: 100%;
        justify-content: center;
        margin-bottom: 16px;

        .spinner-display {
          top: 1px;
        }
      }
    }
  }
}

.toast-container {
  display: flex;
}

.col-message {
  margin-top: 5px;
}

.Toastify__toast-container--top-right {
  width: 370px !important;
}

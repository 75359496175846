@import '@doseme/cohesive-ui/dist/style/colors.scss';

.patient-external-data-container {
  .message-box {
    padding: 0px !important;
    background-color: unset !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
  }

  .patient-external-data {
    display: flex;
    flex-direction: column;
    width: 100%;

    .hover-cursor:hover {
      .patient-external-data-export {
        visibility: visible;
      }
    }
    
    .patient-external-data-export {
      visibility: hidden;
    }

    .patient-external-data-type {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }

    .patient-external-data-pagination-panel {
      margin-top: 20px;
      margin-left: 420px;
      justify-content: flex-end;
      display: flex;
    }
  }
}

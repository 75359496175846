@import '@doseme/cohesive-ui/dist/style/colors.scss';

.clinician-roles-label {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.default-dose-settings-modal {
  width: 560px !important;
}

.hospital-patient-limits {
  display: flex;
  width: 100%;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: $neutrals-100;
  margin-bottom: 16px;
  border-radius: 12px;

  .hospital-patient-limits-label {
    font-size: 14px;
  }

  .co-validation-message {
    padding-bottom: 0px;
  }
}

.co-hospital-show-page {
  .modal-subtitle {
    padding-top: 10px !important;
  }

  .optional-additional-features-tag {
    transform: translateY(3px);
  }

  .optional-additional-features-subtitle {
    color: $neutrals-700;
    margin-bottom: 4px;
    font-weight: 400;
  }
}

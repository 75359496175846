@import '@doseme/cohesive-ui/dist/style/colors.scss';

.session-timeout-modal-wrapper {
  .overlay {
    backdrop-filter: blur(10px);
    z-index: 2000;
  }

  .co-row .co-col {
    padding: 0px;
  }

  .session-timeout-incorrect-password {
    margin-top: 24px;
  }

  .session-timeout-padlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background-color: black;
    margin-right: 16px;
  }

  .session-timeout-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .session-timeout-logout-text {
      cursor: pointer;
      color: $navy;
      text-decoration: underline;
      font-size: 14px;
    }
  }

  .timeout-modal-show-password {
    margin-left: auto;
    font-size: 14px;
    display: flex;
    cursor: pointer;

    .timeout-modal-show-password-label {
      margin-top: 1px;
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.form-slider {
  padding-bottom: 24px;
}
.form-slider-label {
  display: flex;
  font-weight: 700;
  font-size: 14px;

  .tag {
    margin-left: 10px!important;
    margin-top: -5px!important;
    margin-bottom: 10px!important;
  }
}

.form-slider-control {
  display: inline-block;
  background: #F2F2F2;
  width: 144px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;

  .control-label {
    text-align: center;
    display: inline-block;
    vertical-align: bottom;
    padding-bottom: 8px;
    font-weight: 700;
    font-size: 14px;
  }

  .selected {
    font-weight: bold;
  }

}

.preview-panel-top-section-tooltip-menu {
  position: absolute;
  min-width: 250px;
  z-index: 100;
  right: -3px;
  top: 56px;
}

.preview-panel-delete-modal {
  font-size: 16px;

  .tag {
    display: inline-flex;
    flex: none;
    width: auto;
    margin-bottom: 16px;
  }

  .delete-modal-title {
    font-weight: 500;
  }
}

.drug-details {
  display: flex;
  align-items: center;
  position: relative;

  .drug-title-container {
    &.archived {
      width: calc(100% - 260px);
    }

    &.active {
      width: calc(100% - 175px);
    }
  }

  .drug-title {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 4px 0;

    .drug-title-ellipsis-overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  span.model-subtitle {
    font-size: 16px;
    font-weight: 400;
    display: flex;
  }

  .course-preview-ellipsis {
    padding-left: 8px;
  }

  .course-preview-top-buttons {
    display: flex;
    right: 0px;
    position: absolute;

    .course-preview-view-full-course-btn {
      white-space: nowrap;
    }
  }
}

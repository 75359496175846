.analytics-content {
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 86px;

  .analytics-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 56px;
    width: 100%;

    .analytics-title {
      font-size: 24px;
      font-weight: normal;
      padding-right: 24px;

      .analytics-hospital-name {
        font-weight: 700;
      }
    }
  }
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.warning-list {
  font-size: 14px;
}

.warning-sim-subtitle {
  padding-top: 1px;
  color: $grey;
  font-size: 16px;
}

.warning-orange {
  color: $doseme-warning;
}

.warnings-confirm-box {
  display: flex;
  align-items: center;
  background-color: $input-grey;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.875rem;
}

.warning-number {
  display: block;
  min-width: 16px;
  min-height: 16px;
  height: 16px;
  width: 16px;
  line-height: 16px;
  margin-right: 8px;
  border-radius: 10px;
  background-color: $doseme-warning;
  text-align: center;
  color: $white;
  padding-left: 1px;
  font-size: 14px;
  font-weight: bold;
}

.warning-text {
  margin-top: 1px;
}

@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-edit-patient-page {
  width: 560px;
  margin: auto;

  .edit-patient-heading {
    margin-top: 40px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    .edit-patient-title {
      display: inline-block;
      font-size: 24px;
    }

    .edit-patient-back-btn {
      display: inline-block;
      margin-right: 16px;
    }
  }

  .edit-patient-content-panel {
    width: 560px;
    padding: 24px 40px;
    background-color: $white;
    border-radius: 24px;
    box-shadow: 0 0 16px $dark-shadow;
    padding: 24px 32px;

    .edit-patient-content-title {
      font-weight: 700;
      font-size: 20px;
    }

    .edit-patient-inputs-wrapper {
      width: 400px;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;

      .edit-patient-form-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .edit-patient-form-col-left {
          width: 192px;
          margin-right: 16px;
        }

        .edit-patient-form-col-right {
          width: 192px;

          .edit-patient-form-view-existing {
            margin-top: 24px;
          }
        }
      }

      .edit-patient-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 14px;
        align-items: center;
        gap: 5px;

        .edit-patient-button {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

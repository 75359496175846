@import '@doseme/cohesive-ui/dist/style/colors.scss';

.clinician-add-hospital-access-subtitle {
  font-size: 18px;
  margin-top: 8px;
}

.reset-password-modal-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  .reset-password-error {
    margin-top: 32px;
  }

  .reset-password-label {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .reset-password-input-label {
      font-weight: 700;
    }
  
    .reset-password-show-password {
      font-size: 14px;
      display: flex;
      cursor: pointer;

      .reset-password-show-password-label {
        margin-top: 1px;
      }
    }
  }

  .reset-password-validation {
    display: flex;
    margin-top: 16px;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    background-color: $input-grey;
    border-radius: 8px;

    .reset-password-validation-title {
      font-weight: 700;
      font-size: 14px;
    }

    .reset-password-validation-row {
      display: flex;
      flex-direction: row;
      margin-top: 8px;

      .reset-password-validation-text {
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }
}

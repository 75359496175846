@import '@doseme/cohesive-ui/dist/style/colors.scss';

.co-admin-hub {
  max-width: 1080px;
  margin: auto;
  height: 100%;
  justify-content: center;
  align-items: center;

  .admin-hub-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 48px;
    margin-bottom: 8px;
    text-align: left;
    width: fit-content;
  }

  .narrow {
    margin-left: auto;
    margin-right: auto;
    width: min-content;
  }

  .admin-page-panel-block {
    display: flex;
    width: min-content;

    .admin-panel {
      width: 336px;
      height: 240px;
      border-radius: 24px;
      background-color: $white;
      box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
      margin-top: 16px;
      margin-right: 24px;
      padding: 20px 28px;
      position: relative;
      cursor: pointer;
      border-color: $white;
      border-width: 4px;
      border-style: solid;

      &:hover {
        border-color: $blue-steel-200;
      }

      .admin-panel-title {
        font-size: 20px;
        font-weight: 500;

        &.hospital {
          color: $validgreen;
        }

        &.clinicians {
          color: $bright-blue;
        }

        &.changelog {
          color: $magenta;
        }

        &.drugsettings {
          color: $ocean-blue;
        }

        &.vendorsettings {
          color: $doseme-warning;
        }
      }

      .admin-panel-text {
        font-size: 16px;
        margin-top: 16px;
        line-height: 20px;
      }

      .admin-arrow-link {
        position: absolute;
        bottom: 17px;
        right: 32px;
      }
    }
  }
}
